<template>
    <div class="faq">
        <FaqHeader/>
        <div class="faq__container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import FaqHeader from "./components/FaqHeader.vue"

    export default {
        name: 'TheDemoFaq',
        components: {
            FaqHeader
        }
    }
</script>

<style lang="scss" scoped>
    @media screen and (max-width: 1280px){
    }
</style>