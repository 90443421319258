<template>
    <header>
        <img src="../../../../assets/Logo.png" alt="" class="aiku-logo">
    </header>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    header{
        height: 140px;    
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        padding: 0px 80px;
    }

    @media (max-width: 1920px) {

    }

    @media (max-width: 1000px) {
        header{
            padding: 0 30px;
        }
    }

    @media (max-width: 1300px) {
        header{
            height: 120px;
            .aiku-logo{
                width: 40px;
                height: 40px;
            }
        }
    }
</style>